<template>
	<b-modal
		:title="`License No: ${licenseNo}`"
		id="user-drivers-license-image-view"
		:no-close-on-backdrop="true"
		size="md"
		:hide-footer="true"
		centered
	>
		<b-row>
			<b-col sm="12">
				<div align="center" v-if="hasUrl(license)">
					<b-img
						v-img-orientation-changer
						:src="license.image.url"
						alt="Responsive image"
						class="img-responsive"
						fluid
					/>
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'CompanyLogoImageView',
	data() {
		return {
			license: {},
		};
	},
	computed: {
		licenseNo() {
			if (!_.isEmpty(this.license) && !_.isEmpty(this.license.licenseNo)) {
				return this.license.licenseNo;
			}
			return '';
		},
	},
	mounted() {
		EventBus.$on('onSelUserLicenseImageView', (data) => {
			this.license = data.license;
		});
	},
	methods: {
		hasUrl(license) {
			return license && license.image && license.image.url
		}
	},
	beforeDestroy() {
		EventBus.$off('onSelUserLicenseImageView');
	},
};
</script>

<style scoped>
.thumbnail {
	position: relative;
}
.caption {
	position: absolute;
	bottom: 0px;
	left: -10px;
	width: 100%;
}
.strokeme {
	color: white;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000;
}
</style>